import React, {useEffect, useState} from 'react';
import {Trans} from "gatsby-plugin-react-i18next";
import {StaticImage} from "gatsby-plugin-image";
import CompanySettings from "../../constants/company/settings";

import worldMap from '../../images/about/offices-world-map.png';
import thumbPetaluma from '../../images/about/digilock-americas.jpg';
import thumbNetherlands from '../../images/about/digilock-europe-netherlands.jpg';
import thumbAsia from '../../images/about/digilock-asia.jpg';
import thumbHouston from '../../images/about/digilock-houston.jpg';
import thumbChicago from '../../images/about/digilock-showroom-chicago.jpg';
import thumbNYC from '../../images/about/digilock-new-york-city.jpg';


const OfficesDesktop = (props) => {

    const [selectedLocation, setSelectedLocation] = useState('');

    const handleLocationClick = (e) => {
        let cn = e.target.className.split(' ')[1];
        setSelectedLocation(cn);
        switch (cn) {
            case 'petaluma':
                window.location = '/about/offices/#gotoAmericas';
            break;
            case 'houston':
                window.location = '/about/offices/#gotoAmericas';
                break;
            case 'chicago':
                window.location = '/about/offices/#gotoAmericas';
                break;
            case 'nyc':
                window.location = '/about/offices/#gotoAmericas';
            break;
            case 'netherlands':
                window.location = '/about/offices/#gotoEurope';
                break;
            default:
                window.location = '/about/offices/#gotoEurope';
        }
    }

    const handleMapClick = () => {
        setSelectedLocation('');
    }

    useEffect(() => {
        Array.from(document.getElementsByClassName("marker"))
            .forEach(function (element) {
                element.addEventListener("click", handleLocationClick);
            });
    }, []);

    return (
        <section className="global-offices desktop">
            <div class="container">
                <div className={selectedLocation === 'petaluma' ? "marker petaluma selected tooltip" : "marker petaluma tooltip"}>
                    <div className="right">
                        <img src={thumbPetaluma} width={'342'} height={'228'} alt="Petaluma, CA"/>
                        <p>Petaluma, CA</p>
                        <i></i>
                    </div>
                </div>
                <div className={selectedLocation === 'houston' ? "marker houston selected tooltip" : "marker houston tooltip"}>
                    <div className="right">
                        <img src={thumbHouston} width={'342'} height={'228'} alt="Houston, TX"/>
                        <p>Houston, TX</p>
                        <i></i>
                    </div>
                </div>
                <div className={selectedLocation === 'chicago' ? "marker chicago selected tooltip" : "marker chicago tooltip"}>
                    <div className="right">
                        <img src={thumbChicago} width={'342'} height={'228'} alt="Chicago, IL"/>
                        <p>Chicago, IL</p>
                        <i></i>
                    </div>
                </div>
                <div className={selectedLocation === 'nyc' ? "marker nyc selected tooltip" : "marker nyc tooltip"}>
                    <div className="right">
                        <img src={thumbNYC} width={'342'} height={'228'} alt="New York, NY"/>
                        <p>New York, NY</p>
                        <i></i>
                    </div>
                </div>
                <div className={selectedLocation === 'netherlands' ? "marker netherlands selected tooltip" : "marker netherlands tooltip"}>
                    <div className="right">
                        <img src={thumbNetherlands} width={'342'} height={'228'} alt="Amsterdam, The Netherlands"/>
                        <p>Amsterdam, The Netherlands</p>
                        <i></i>
                    </div>
                </div>
                <div className={selectedLocation === 'hk' ? "marker hk selected tooltip" : "marker hk tooltip"}>
                    <div className="right">
                        <img src={thumbAsia} width={'342'} height={'228'} alt="Lai Chi Kok, Kowloon, Hong Kong" />
                        <p>Lai Chi Kok, Kowloon, Hong Kong</p>
                        <i></i>
                    </div>
                </div>
                <div className={'world-map'} onClick={handleMapClick}>
                    <StaticImage
                        style={{zIndex:'-1'}}
                        src="../../images/about/offices-world-map.png"
                        width={726}
                        height={376}
                        formats={["auto", "webp", "avif"]}
                        alt="Digilock Netherlands"
                    />
                </div>

            </div>
        </section>
    )

}

export default OfficesDesktop;