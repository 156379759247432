import React from 'react';
import {Trans} from "gatsby-plugin-react-i18next";
import {Accordion, AccordionItem as Item} from "@szhsin/react-accordion";
import CompanySettings from "../../constants/company/settings";
import thumbPetaluma from "../../images/about/digilock-americas.jpg";
import thumbNetherlands from "../../images/about/digilock-europe-netherlands.jpg";
import thumbAsia from "../../images/about/digilock-asia.jpg";
import thumbChicago from "../../images/about/digilock-showroom-chicago.jpg";
import thumbNYC from "../../images/about/digilock-new-york-city.jpg";
import thumbHouston from "../../images/about/digilock-houston.jpg";
import plusSign from '../../images/shared/accordian-plus.png';
import {StaticImage} from "gatsby-plugin-image";


const OfficesMobile = (props) => {
  const AccordionItem = ({ header, ...rest }) => (
      <Item
          {...rest}
          header={
            <>
              {header}
              <div className="accordion-open-close" />
            </>
          }
      />
  );
  return (
      <section className="global-offices">
        <div className="container offices">
          <Accordion>
            <AccordionItem header="Digilock Americas">
              <div>
                <div class="expander" />
                <figure>
                  {/*<img src={thumbPetaluma} width={'192'} height={' 128'} alt="Petaluma offices"/>*/}
                  <StaticImage
                      src="../../../images/about/digilock-americas.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Digilock Petaluma"
                  />
                  <figcaption>
                    <span><Trans>Petaluma</Trans></span>
                    <p>1 Willowbrook Court</p>
                    <p>Petaluma, CA 94954</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-showroom-chicago.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Digilock Chicago"
                  />
                  <figcaption>
                    <Trans>Chicago</Trans>
                    <p>theMART, Floor 10, Suite 1098</p>
                    <p>Chicago, IL 60654</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-new-york-city.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Digilock New York"
                  />
                  <figcaption>
                    <Trans>New York</Trans>
                    <p>400 Madison Avenue</p>
                    <p>New York, NY 10017</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-houston.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Offices"
                  />
                  <figcaption>
                    <Trans>Houston</Trans>
                    <p>3367 N Sam Houston Pkwy W</p>
                    <p>Houston, TX 77038</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SALES_EMAIL}>{CompanySettings.SALES_EMAIL}</a></p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SALES_AMERICAS}>{CompanySettings.PHONE_SALES_AMERICAS}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SUPPORT_EMAIL}>{CompanySettings.SUPPORT_EMAIL}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SUPPORT_AMERICAS}>{CompanySettings.PHONE_SUPPORT_AMERICAS}</a>
                    </p>
                  </figcaption>
                </figure>
              </div>
            </AccordionItem>
          </Accordion>
          <Accordion>
            <AccordionItem header="Digilock Europe BV">
              <div>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-europe-netherlands.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Offices"
                  />
                  <figcaption>
                    <Trans>The Netherlands</Trans>
                    <p>Schillingweg 22 2153 PL, <br />Nieuw-Vennep</p>
                    <p>Amsterdam, The Netherlands</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SALES_EMAIL_EUROPE}>{CompanySettings.SALES_EMAIL_EUROPE}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SALES_EUROPE}>{CompanySettings.PHONE_SALES_EUROPE}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SUPPORT_EMAIL_EUROPE}>{CompanySettings.SUPPORT_EMAIL_EUROPE}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SUPPORT_EUROPE}>{CompanySettings.PHONE_SUPPORT_EUROPE}</a>
                    </p>
                  </figcaption>
                </figure>
              </div>
            </AccordionItem>
          </Accordion>
          <Accordion>
            <AccordionItem header="Digilock Asia">
              <div>
                <figure>
                  <StaticImage
                      src="../../../images/about/digilock-asia.jpg"
                      width={342}
                      height={228}
                      formats={["auto", "webp", "avif"]}
                      alt="Offices"
                  />
                  <figcaption>
                    <Trans>Hong Kong</Trans>
                    <p>Flat B, 27th Floor Grandion Plaza</p>
                    <p>No. 932 Cheung Sha Wan Road</p>
                    <p>Lai Chi Kok, Kowloon, Hong Kong</p>
                    <span><Trans>sales</Trans></span>
                    <p><Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SALES_EMAIL_ASIA}>{CompanySettings.SALES_EMAIL_ASIA}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SALES_ASIA}>{CompanySettings.PHONE_SALES_ASIA}</a>
                    </p>
                    <span><Trans>support</Trans></span>
                    <p>
                      <Trans>email</Trans>: <a
                        href={'mailto:' + CompanySettings.SUPPORT_EMAIL_ASIA}>{CompanySettings.SUPPORT_EMAIL_ASIA}</a>
                    </p>
                    <p>
                      <Trans>phone</Trans>: <a
                        href={'tel:' + CompanySettings.PHONE_SUPPORT_ASIA}>{CompanySettings.PHONE_SUPPORT_ASIA}</a>
                    </p>
                  </figcaption>
                </figure>
              </div>
            </AccordionItem>
          </Accordion>

          <div>


          </div>
        </div>
      </section>
  )

}

export default OfficesMobile;